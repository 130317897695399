import React, { FunctionComponent, useEffect, useState, useRef } from "react";
import { ConveySocket, ConveyApi, ILang, CNeuralNames, CNueralDialectMap } from "@utils";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import { formatPhoneNumber, formatSentence, delay } from "@lib/utils/helpers";
import moment from "moment-timezone";
import ApexCharts from "apexcharts";
import { SwitchWrap, SwitchItem, ActionsContainer, FlexCenterRow, FormInput } from "@components/styles";
import Switch from "react-switch";
import {
  KeywordsContainer,
  Keyword,
  KeywordTimestamp,
  AudioWrap,
  UploadWrap,
  TranscribeStatus,
  TranscribeWrapContainer,
  TranscribeContainer,
  TranscribeText,
  TranscribeTextHeader,
  TranscribeTextIdentity,
  TranscribeWrap,
  TranslateWrap,
  TranscribeNote,
  TranslatedText,
  SentimentContainer,
  SentimentScore
} from "./transcribeStyles";
// @ts-ignore
import Play from "@assets/icons/play.svg";
// @ts-ignore
import Pause from "@assets/icons/pause.svg";

interface ITranscribeChatProps {
  agencyId: string;
  correlationId: string;
  callerLanguage: string;
  talkback?: boolean;
  showUploader?: boolean;
  conversationId?: string;
  $summaryContainer?: string;
  $sentimentContainer?: string;
  $keywordsContainer?: string;
  neuralName: string;
  app: IRootState["app"];
  agency: IRootState["agency"];
  conversations: IRootState["conversations"];
  callback?: (eventData: Record<any, any>) => void;
  onKeywordCallback?: (eventData: Record<any, any>, isNew: boolean) => void;
  onSentimentScoreCallback?: (eventData: Record<any, any>, isNew: boolean) => void;
  onSummaryCallback?: (eventData: string, isNew: boolean) => void;
  onLiveAudioCallback?: (eventData: Record<any, any>) => void;
  recipientAudioFile?: File;
  agentAudioFile?: File;
}

const XTranscribeChat: FunctionComponent = ({
  correlationId,
  callback,
  onKeywordCallback,
  onSummaryCallback,
  onSentimentScoreCallback,
  onLiveAudioCallback,
  showUploader,
  callerLanguage,
  app,
  conversationId,
  conversations,
  recipientAudioFile,
  agentAudioFile,
  agency,
  $summaryContainer,
  $sentimentContainer,
  $keywordsContainer
}: ITranscribeChatProps) => {
  const { agencyId } = app;
  const [_, setTranscriptions] = useState([]);
  const [foundKeywords, setFoundKeywords] = useState({});
  const foundKeywordsRef = useRef([]);
  const allKeywordsRef = useRef({});
  const hasAudioRef = useRef(false);
  const autoScrollRef = useRef(true);
  const wsRef = useRef({});
  const transcriptsIdentitiesRef = useRef([]);
  const sentimentScoresRef = useRef([]);
  const darkThemeRef = useRef(false);
  const audioArray = useRef<Float32Array | null>(null);
  const transcriptsRef = useRef([]);
  const audioNodeRef = useRef<AudioWorkletNode>(null);
  const audioContextRef = useRef<AudioContext>(null);
  const wsConnectInterval = useRef<NodeJS.Timer | null>(null);
  const sentimentChartRef = useRef(null);
  const keywordsChartRef = useRef(null);
  const sentimentChartReadyRef = useRef(false);
  const keywordsChartReadyRef = useRef(false);
  const [backgroundColor, setBackgroundColor] = useState("#fff");
  const [sentimentScore, setSentimentScore] = useState("");
  const [showAudioPlay, setShowAudioPlay] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const [hasAudio, setHasAudio] = useState(false);
  const [audioNodeObj, setAudioNodeObj] = useState<AudioWorkletNode>();
  const [agentSelected, setAgentSelected] = useState(false);
  const [callerSelected, setCallerSelected] = useState(false);
  const [search, setSearch] = useState("");
  const currentLang = JSON.parse(localStorage.getItem("neuralSelection"));
  const currentConversation = conversations[conversationId];
  const [lang, setLang] = useState<ILang>(
    currentLang
      ? { outputLanguage: currentLang.outputLanguage, neuralName: currentLang.neuralName }
      : {
          outputLanguage: "en-US",
          neuralName: "en-US-JennyNeural"
        }
  );

  const scrollToBottom = () => {
    setTimeout(() => {
      const wrapperEle = document.getElementById(`transcription-container-${correlationId}`);
      if (wrapperEle && autoScrollRef.current) {
        wrapperEle.scrollTo(0, 100000);
      }
    }, 250);
  };

  const findKeywords = (data: Record<any, any>): string[][] => {
    const found = [];
    const dataRoot = data?.event ? data.event : data;
    return dataRoot.channel?.search
      ?.flatMap((searchItem) => {
        if (searchItem.hits.length > 0) {
          return searchItem.hits.map((hit) => {
            if (hit.confidence >= 0.9) {
              if (!found.includes(searchItem.hits[0].snippet)) {
                found.push(searchItem.hits[0].snippet);
                return [searchItem.query, searchItem.hits[0].snippet];
              }
            }
          });
        } else {
          return null;
        }
      })
      .filter(Boolean);
  };

  useEffect(() => {
    if (CNueralDialectMap[callerLanguage]) {
      const nueralNameIndex = CNueralDialectMap[callerLanguage];
      const lang = CNeuralNames[nueralNameIndex];
      localStorage.setItem(
        "neuralSelection",
        JSON.stringify({
          language: nueralNameIndex,
          outputLanguage: lang.outputLanguage,
          neuralName: lang.neuralName
        })
      );
      setLang(lang);
    }
  }, [callerLanguage]);

  const getTimestamp = (updatedAt) => {
    try {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const formattedTime = (updatedAt ? moment(updatedAt) : moment()).tz(tz).format("h:mm:ss a");
      return formattedTime;
    } catch (e) {
      return updatedAt;
    }
  };

  useEffect(() => {
    (async () => {
      const keywords = await ConveyApi.getKeywords();
      const keywordsObj = {};
      keywords.forEach((keyword) => {
        keywordsObj[keyword.term] = keyword;
      });
      allKeywordsRef.current = keywordsObj;
      if (!conversationId) {
        return;
      }
      const messagesResponse = await ConveyApi.getSourceMessages({ source: "transcribe", conversationId });
      transcriptsRef.current = [
        ...transcriptsRef.current,
        ...messagesResponse
          .filter((e) => e.source === "transcribe")
          .map((m) => {
            const formattedIdentity =
              m.direction === "outbound"
                ? app.agencyName
                : formatPhoneNumber(
                    m.recipient_label && m.recipient_label != "" ? m.recipient_label : m.recipient_number
                  );

            if (!transcriptsIdentitiesRef.current.includes(formattedIdentity)) {
              transcriptsIdentitiesRef.current.push(formattedIdentity);
            }

            let highlightWords = [];
            let parsedData = null;
            if (m.metadata) {
              try {
                parsedData = typeof m.metadata === "string" ? JSON.parse(m.metadata) : m.metadata;
                if (parsedData.provider_data) {
                  if (
                    ["deepgram", "azure"].includes(parsedData.provider_data?.provider) &&
                    parsedData.provider_data?.data
                  ) {
                    highlightWords = findKeywords(parsedData.provider_data?.data);
                  }
                }
              } catch (error) {
                console.log({ error });
                // Handle the error here
              }
            }

            const translated = !!m.body_transl && !!m.language && m.language !== "en" && m.body !== m.body_transl;
            let body = translated ? m.body_transl : m.body;
            const originalBody = body;

            if (highlightWords && highlightWords.length > 0) {
              highlightWords.forEach((words) => {
                if (words[1] && body.toLowerCase().includes(words[1])) {
                  const regEx = new RegExp(words[1], "g");
                  body = formatSentence(
                    body.toLowerCase().replace(regEx, '<strong style="color: red">' + words[1] + "</strong>")
                  );
                  const payload = {
                    term: words[0],
                    data: keywordsObj[words[0]],
                    id: m.message_id,
                    created_at: m.created_at,
                    body: originalBody
                  };
                  foundKeywordsRef.current.push(payload);
                  onKeywordCallback && onKeywordCallback(payload, false);
                }
              });
            }
            setFoundKeywords(foundKeywordsRef.current);
            updateKeywordChart();
            return {
              id: m.message_id,
              identity: formattedIdentity,
              dialogue: body,
              created_at: m.created_at,
              direction: m.direction,
              line: [formattedIdentity, body].join(": "),
              data: translated
                ? {
                    languages: {
                      from: m.language,
                      to: "en"
                    },
                    translations: {
                      from: m.body,
                      to: m.body_transl
                    }
                  }
                : {}
            };
          })
      ];
      setTranscriptions(transcriptsRef.current);
      setTimeout(() => {
        scrollToBottom();
      }, 500);
    })();
  }, [conversationId]);

  const mergeBuffer = (a, b) => {
    const c = new a.constructor(a.length + b.length);
    c.set(a);
    c.set(b, a.length);

    return c;
  };

  const convertBlock = (byteArray) => {
    // Create a new Float32Array to hold the float data
    const intArray = new Uint8Array(byteArray);
    const float32Array = new Float32Array(intArray.byteLength / 2);
    // Iterate through the byte array two bytes at a time (for 16-bit samples)
    for (let i = 0; i < intArray.byteLength; i += 2) {
      // Combine the two bytes to form a 16-bit signed integer
      const int16 = (intArray[i + 1] << 8) | (intArray[i] & 0xff);
      // If the integer is negative, handle the sign correctly
      const signedInt16 = int16 >= 0x8000 ? int16 - 0x10000 : int16;
      // Normalize the 16-bit integer to the range [-1.0, 1.0] and store it as a float
      float32Array[i / 2] = signedInt16 / 32768.0;
    }
    return float32Array;
  };

  const openWs = (role) => {
    const wsUrl = `${
      process.env.WS_RTP_HOST
    }?conversationId=${conversationId}&phoneNumber=${correlationId}&primaryRecipient=${correlationId}&agencyId=${
      app.agencyId
    }&source=voice&recipientFlow=true&identity=${
      role === "recipient" ? correlationId : "%2B12222222222"
    }&role=${role}&transcribeProvider=azure&encoding=linear16&langID=true`;
    wsRef.current[role] = new WebSocket(wsUrl);
    wsRef.current[role].binaryType = "arraybuffer";
  };

  const startLangID = async (role: string) => {
    await ConveyApi.updateRecipients({
      number: "12222222222",
      conversationId
    });
    if (!wsRef.current[role]) {
      openWs(role);
    }
    if (wsRef.current[role].readyState === WebSocket.OPEN) {
      initAudio(role);
    } else {
      wsRef.current[role].addEventListener("open", () => {
        initAudio(role);
      });
      wsRef.current[role].addEventListener("close", () => {
        if (!wsConnectInterval.current) {
          wsConnectInterval.current = setInterval(() => {
            if (wsRef.current[role].readyState !== WebSocket.OPEN) {
              openWs(role);
            }
          }, 1000);
        }
      });
      wsRef.current[role].addEventListener("error", () => {
        if (!wsConnectInterval.current) {
          wsConnectInterval.current = setInterval(() => {
            if (wsRef.current[role].readyState !== WebSocket.OPEN) {
              openWs(role);
            }
          }, 1000);
        }
      });
    }
  };

  const startDualLangID = () => {
    startLangID("recipient");
    startLangID("agent");
  };

  useEffect(() => {
    if (agentAudioFile && recipientAudioFile) {
      setTimeout(() => {
        startDualLangID();
      }, 750);
    }
  }, [agentAudioFile, recipientAudioFile]);

  const initAudio = async (role) => {
    let file = null;
    if (agentAudioFile && role === "agent") {
      file = agentAudioFile;
    } else if (recipientAudioFile && role === "recipient") {
      file = recipientAudioFile;
    } else {
      const files = (document.getElementById(`${role}File`) as HTMLInputElement).files;
      file = files[0];
    }
    if (!file) {
      return;
    }
    const chunksize = 320;
    let offset = 0;
    while (offset < file.size) {
      const chunkfile = await file.slice(offset, offset + chunksize);
      // Blob.arrayBuffer() can be polyfilled with a FileReader
      const chunk = await chunkfile.arrayBuffer();
      if (wsRef.current[role].readyState === WebSocket.OPEN) {
        wsRef.current[role].send(chunk);
      }
      await delay(20);
      offset += chunksize;
    }
  };

  const updateSummary = (summary: string) => {
    if (!$summaryContainer) {
      return;
    }
    const summaryItems = `${summary}`
      .split(".")
      .filter((item) => !!item)
      .map((item) => `${item}.`);
    if ($summaryContainer) {
      const $summaryEl = document.getElementById($summaryContainer);
      if ($summaryEl) {
        const $ul = document.createElement("ul");
        summaryItems.forEach((item: string) => {
          const $li = document.createElement("li");
          $li.innerHTML = item;
          $ul.appendChild($li);
        });
        $summaryEl.replaceChildren($ul);
      }
    }
  };

  useEffect(() => {
    if (currentConversation?.entity?.summary) {
      updateSummary(currentConversation?.entity?.summary);
      onSummaryCallback && onSummaryCallback(currentConversation?.entity?.summary, false);
    }

    const socketInstance = ConveySocket.init(ConveyApi.readAccessToken());
    const sipSocketInstance = ConveySocket.initSip({ agencyId, correlationId });

    const updateConversationHandler = (data) => {
      if (data.summary) {
        updateSummary(data.summary);
        onSummaryCallback && onSummaryCallback(data.summary, true);
      }
    };

    const sipAudioHandler = async (data) => {
      hasAudioRef.current = true;
      setHasAudio(true);
      audioArray.current = mergeBuffer(
        audioArray.current ? audioArray.current : new Float32Array(0),
        convertBlock(data)
      );
      if (audioArray.current && audioArray.current.byteLength > 640) {
        onLiveAudioCallback && onLiveAudioCallback(audioArray.current);

        if (audioNodeRef.current) {
          audioNodeRef.current.port.postMessage({ message: "audioData", audioData: audioArray.current });
        }
        audioArray.current = new Float32Array(0);
      }
    };

    const sipSentimentHandler = (data) => {
      if (data.hexColor) {
        setBackgroundColor(`#${data.hexColor}`);
      }
      if (data.providerData?.data?.score) {
        setSentimentScore(data.providerData?.data?.score);
        const createdAt = moment.utc().valueOf();
        if (sentimentScoresRef.current.length > 0) {
          const firstScore = sentimentScoresRef.current[0];
          sentimentScoresRef.current.push({
            score: data.providerData?.data?.score,
            timestamp: (createdAt - firstScore.createdAt) / 1000,
            createdAt
          });
        } else {
          sentimentScoresRef.current.push({
            score: data.providerData?.data?.score,
            timestamp: 0,
            createdAt
          });
        }
        onSentimentScoreCallback && onSentimentScoreCallback(data, true);
        updateSentimentChart();
      }
    };

    const sipRoomHandler = (data) => {
      const { direction, status, transcription, translations, identity, languages, id } = data;

      callback && callback(data);

      let highlightWords = [];

      if (data.providerData) {
        if (["deepgram", "azure"].includes(data.providerData?.provider)) {
          highlightWords = findKeywords(data.providerData?.data);
        }
      }

      const currentLength = transcriptsRef.current.length;
      let dialogue;
      if (languages && languages.to.substring(0, 2) == "en")
        dialogue = translations.to ? translations.to : transcription;
      const originalBody = dialogue;

      const formattedIdentity = formatPhoneNumber(data.identity);

      if (!dialogue || dialogue == "") {
        return;
      }

      if (highlightWords && highlightWords.length > 0) {
        highlightWords.forEach((words) => {
          if (words[1] && dialogue.toLowerCase().includes(words[1])) {
            const regEx = new RegExp(words[1], "g");
            dialogue = formatSentence(
              dialogue.toLowerCase().replace(regEx, '<strong style="color: red">' + words[1] + "</strong>")
            );
            const keywordPayload = {
              term: words[0],
              data: allKeywordsRef.current[words[0]],
              id,
              created_at: data.createdAt,
              body: originalBody
            };

            const exists = foundKeywordsRef.current.findIndex((t) => t.id === id && t.term === words[0]);
            if (exists === -1) {
              foundKeywordsRef.current.push(keywordPayload);
              onKeywordCallback && onKeywordCallback(keywordPayload, true);
            }
          }
        });
      }
      setFoundKeywords(foundKeywordsRef.current);
      updateKeywordChart();
      const line = `${formattedIdentity}: ${dialogue}`;

      if (!transcriptsIdentitiesRef.current.includes(formattedIdentity)) {
        transcriptsIdentitiesRef.current.push(formattedIdentity);
      }

      if (currentLength > 0) {
        const lastTranscription = transcriptsRef.current[currentLength - 1];

        if (
          identity == lastTranscription.identity &&
          dialogue.toLowerCase() == lastTranscription.dialogue.toLowerCase()
        ) {
          return;
        }

        if (lastTranscription.status == "recognized") {
          const currentIdIndex = transcriptsRef.current.findIndex((t) => t.id === id);
          if (currentIdIndex !== -1) {
            transcriptsRef.current[currentIdIndex] = {
              line,
              dialogue,
              identity: formattedIdentity,
              transcription,
              status,
              direction,
              id,
              created_at: data.createdAt,
              data
            };
          } else {
            transcriptsRef.current = [
              ...transcriptsRef.current,
              {
                line,
                dialogue,
                identity: formattedIdentity,
                transcription,
                status,
                direction,
                id,
                created_at: data.createdAt,
                data
              }
            ];
          }
        } else {
          const currentIdIndex = transcriptsRef.current.findIndex((t) => t.id === id);
          if (currentIdIndex !== -1) {
            transcriptsRef.current[currentIdIndex] = {
              line,
              dialogue,
              identity: formattedIdentity,
              transcription,
              status,
              direction,
              id,
              created_at: data.createdAt,
              data
            };
          } else {
            if (identity != lastTranscription.identity) {
              transcriptsRef.current = [
                ...transcriptsRef.current,
                {
                  line,
                  dialogue,
                  identity: formattedIdentity,
                  transcription,
                  status,
                  direction,
                  id,
                  created_at: data.createdAt,
                  data
                }
              ];
            } else {
              transcriptsRef.current.pop();
              transcriptsRef.current = [
                ...transcriptsRef.current,
                {
                  line,
                  dialogue,
                  identity: formattedIdentity,
                  transcription,
                  status,
                  direction,
                  id,
                  created_at: data.createdAt,
                  data
                }
              ];
            }
          }
        }
      } else {
        transcriptsRef.current = [
          ...transcriptsRef.current,
          {
            line,
            dialogue,
            identity: formattedIdentity,
            transcription,
            status,
            direction,
            id,
            created_at: data.createdAt,
            data
          }
        ];
      }
      setTranscriptions(transcriptsRef.current);
      setTimeout(() => {
        scrollToBottom();
      }, 250);
    };

    socketInstance.listen(ConveySocket.UPDATE_CONVERSATION, updateConversationHandler, true);

    sipSocketInstance
      .listen(ConveySocket.getSipAudio({ agencyId, correlationId }), sipAudioHandler)
      .listen(ConveySocket.getSipSentiment({ agencyId, correlationId }), sipSentimentHandler)
      .listen(ConveySocket.getSipRoom({ agencyId, correlationId }), sipRoomHandler, true);
    darkThemeRef.current = document.body.getAttribute("data-theme") === "dark";
    if ($sentimentContainer) {
      const options = {
        chart: {
          type: "line",
          height: 350,
          toolbar: {
            show: false
          }
        },
        theme: {
          mode: darkThemeRef.current ? "dark" : "light"
        },
        series: [
          {
            name: "Score",
            data: []
          }
        ],
        xaxis: {
          categories: [],
          type: "numeric",
          tickAmount: 2,
          labels: {
            formatter: function (value: any) {
              return parseFloat(value).toFixed(3); // Show floats with 3 decimal places
            }
          }
        },
        yaxis: {
          min: -10,
          max: 10,
          forceNiceScale: true
        },
        stroke: {
          width: 2
        }
      };
      sentimentChartRef.current = new ApexCharts(document.querySelector(`#${$sentimentContainer}`), options);
      sentimentChartRef.current.render().then(() => {
        sentimentChartReadyRef.current = true;
      });
    }

    if ($keywordsContainer) {
      const options = {
        legend: {
          show: false
        },
        series: [
          {
            name: "Keywords",
            data: []
          }
        ],
        theme: {
          mode: darkThemeRef.current ? "dark" : "light"
        },
        chart: {
          height: 350,
          type: "treemap",
          toolbar: {
            show: false
          }
        },
        title: {
          text: "",
          align: "center"
        },
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false
          }
        }
      };
      keywordsChartRef.current = new ApexCharts(document.querySelector(`#${$keywordsContainer}`), options);
      keywordsChartRef.current.render().then(() => {
        keywordsChartReadyRef.current = true;
      });
    }

    const checkDarkMode = () => {
      const theme = document.body.getAttribute("data-theme");
      darkThemeRef.current = theme === "dark";
      if (sentimentChartRef.current) {
        updateSentimentChart();
      }
    };

    const observerCallback = (mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.attributeName === "data-theme") {
          checkDarkMode();
        }
      }
    };

    const observer = new MutationObserver(observerCallback);
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["data-theme"]
    });

    return () => {
      socketInstance.listenOff(ConveySocket.UPDATE_CONVERSATION, updateConversationHandler);

      sipSocketInstance
        .listenOff(ConveySocket.getSipAudio({ agencyId, correlationId }), sipAudioHandler)
        .listenOff(ConveySocket.getSipSentiment({ agencyId, correlationId }), sipSentimentHandler)
        .listenOff(ConveySocket.getSipRoom({ agencyId, correlationId }), sipRoomHandler);

      if (sentimentChartRef.current) {
        sentimentChartRef.current.destroy();
      }
      if (keywordsChartRef.current) {
        keywordsChartRef.current.destroy();
      }
      observer.disconnect();
    };
  }, []);

  const updateKeywordChart = () => {
    if (keywordsChartRef.current && keywordsChartReadyRef.current) {
      const currentKeywords = {};

      foundKeywordsRef.current.forEach((keyword) => {
        if (!currentKeywords[keyword.term]) {
          currentKeywords[keyword.term] = [];
        }
        const isExist = currentKeywords[keyword.term].find((item) => item.id == keyword.id);
        if (!isExist) {
          currentKeywords[keyword.term].push(keyword);
        }
      });

      const series = [
        {
          name: "Keywords",
          data: Object.keys(currentKeywords).map((termKey) => {
            const keyword = currentKeywords[termKey];
            return {
              x: termKey,
              y: keyword.length
            };
          })
        }
      ];

      const colors = Object.keys(currentKeywords).map((termKey) => {
        const keyword = currentKeywords[termKey];
        let color = "#ef5350";
        if (keyword && keyword[0] && keyword[0].data) {
          const level = keyword[0].data.level;
          if (level == 1) {
            color = "#ef5350";
          } else if (level == 2) {
            color = "#ffb22b";
          } else if (level == 3) {
            color = "#186dde";
          }
        }
        return color;
      });
      keywordsChartReadyRef.current = false;
      keywordsChartRef.current
        .updateOptions({
          colors,
          series,
          theme: {
            mode: darkThemeRef.current ? "dark" : "light"
          }
        })
        .then(() => {
          keywordsChartReadyRef.current = true;
        });
    }
  };

  const updateSentimentChart = () => {
    if (sentimentChartRef.current && sentimentChartReadyRef.current) {
      const options = {
        chart: {
          width: "100%"
        },
        xaxis: {
          categories: sentimentScoresRef.current.map((item) => item.timestamp)
        },
        theme: {
          mode: darkThemeRef.current ? "dark" : "light"
        },
        series: [
          {
            name: "Score",
            data: sentimentScoresRef.current.map((item) => item.score)
          }
        ]
      };
      sentimentChartReadyRef.current = false;
      sentimentChartRef.current.updateOptions(options).then(() => {
        sentimentChartReadyRef.current = true;
      });
    }
  };

  const initAudioContext = async () => {
    if (audioNodeRef.current) {
      return;
    }
    try {
      const audioContext = new AudioContext({ sampleRate: 8000 });
      await audioContext.audioWorklet
        .addModule("https://convey-embed-scriptlets.s3.us-east-2.amazonaws.com/AudioProcessor.js")
        .catch((e) => {
          console.error(e);
          console.trace();
        });
      const audioNode = new AudioWorkletNode(audioContext, "audio-processor");
      audioNode.connect(audioContext.destination);
      audioNodeRef.current = audioNode;
      setAudioNodeObj(audioNode);
      audioContextRef.current = audioContext;
      audioContextRef.current.suspend();
      setShowAudioPlay(true);
    } catch (e) {
      console.log({ e });
    }
  };

  const dialectLabel = (code: string) => {
    let label = "";
    try {
      label = app.textDialects.find((o) => o.code === code).label;
    } catch (error) {
      return code;
    }
    return label;
  };

  const openUploader = (id: string) => {
    document.getElementById(id).click();
  };

  return (
    <div onClick={initAudioContext}>
      <ActionsContainer style={{ height: 60, padding: "0 10px" }}>
        <FlexCenterRow justify="space-between" style={{ width: "100%" }}>
          <FlexCenterRow justify="start">
            {!app.initOptions.options.disableShowLiveAudio && showAudioPlay && hasAudio && (
              <AudioWrap
                onClick={(e) => {
                  e.preventDefault();
                  if (audioContextRef.current) {
                    if (isPlaying) {
                      audioContextRef.current.suspend();
                    } else {
                      audioContextRef.current.resume();
                    }
                    setIsPlaying(!isPlaying);
                  }
                }}
              >
                {isPlaying ? <Pause /> : <Play />}
                LIVE
              </AudioWrap>
            )}
          </FlexCenterRow>
          <FlexCenterRow justify="start">
            {showUploader && (
              <>
                <UploadWrap>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      openUploader("recipientFile");
                    }}
                  >
                    Select Recipient Audio
                  </a>
                  <input
                    id="recipientFile"
                    hidden={true}
                    type="file"
                    onChange={() => {
                      setCallerSelected(true);
                    }}
                    accept="audio/*"
                  />
                </UploadWrap>
                <UploadWrap>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      openUploader("agentFile");
                    }}
                  >
                    Select Agent Audio
                  </a>
                  <input
                    id="agentFile"
                    hidden={true}
                    type="file"
                    onChange={() => {
                      setAgentSelected(true);
                    }}
                    accept="audio/*"
                  />
                </UploadWrap>
                {agentSelected && callerSelected && (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      startDualLangID();
                    }}
                  >
                    Send Audio
                  </a>
                )}
              </>
            )}
            <SwitchWrap style={{ paddingTop: 10, margin: "0 20px" }}>
              <SwitchItem>
                <Switch
                  onChange={(checked) => {
                    autoScrollRef.current = !!checked;
                    setAutoScroll(!!checked);
                  }}
                  checked={autoScroll}
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
                Autoscroll
              </SwitchItem>
            </SwitchWrap>
            <FormInput
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Search"
              value={search}
              style={{
                boxSizing: "border-box",
                padding: 6,
                width: "100%",
                maxWidth: 600,
                borderRadius: 4,
                border: "1px solid hsl(0, 0%, 80%)",
                minHeight: 38,
                flex: 1
              }}
            />
          </FlexCenterRow>
        </FlexCenterRow>
      </ActionsContainer>
      <TranscribeWrap style={{ flexDirection: "row", height: "calc(100% - 84px)", top: 62 }}>
        {app.initOptions.options.enableSentimentAnalysis && (
          <SentimentContainer style={{ backgroundColor }}>
            {sentimentScore && <SentimentScore>{sentimentScore}</SentimentScore>}
          </SentimentContainer>
        )}
        <TranscribeWrapContainer>
          <TranscribeContainer id={`transcription-container-${correlationId}`}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                width: "100%"
              }}
            >
              {transcriptsRef.current.map((transcript) => {
                return (
                  (search === "" || transcript.dialogue.includes(search)) && (
                    <div
                      key={transcript.id}
                      id={`${transcript.id}`}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: transcript.direction !== "outbound" ? "flex-start" : "flex-end",
                        width: "100%"
                      }}
                    >
                      <TranscribeText
                        id={`transcribe-msg-${transcript.id}`}
                        direction={transcript.direction}
                        className={`transcript-item transcript-identity-${
                          transcript.identity !== app.agencyName
                            ? transcriptsIdentitiesRef.current.indexOf(transcript.identity)
                            : "responder"
                        }`}
                      >
                        <TranscribeTextHeader>
                          <TranscribeTextIdentity>
                            {transcript.direction === "outbound" ? app.agencyName : transcript.identity}
                          </TranscribeTextIdentity>
                          <TranscribeStatus direction={transcript.direction}>
                            {getTimestamp(transcript.created_at)}
                          </TranscribeStatus>
                        </TranscribeTextHeader>
                        <span dangerouslySetInnerHTML={{ __html: transcript.dialogue }} />
                        {transcript?.data?.languages?.from &&
                          transcript?.data?.translations?.from &&
                          transcript?.data?.languages?.from !== transcript?.data?.languages?.to &&
                          transcript?.data?.translations?.from !== transcript?.data?.translations?.to && (
                            <TranslateWrap>
                              {transcript?.data?.languages?.from && (
                                <TranscribeNote emphasized={true}>
                                  Translated from {dialectLabel(transcript?.data?.languages?.from)}
                                </TranscribeNote>
                              )}
                              <TranslatedText>{transcript?.data?.translations?.from}</TranslatedText>
                            </TranslateWrap>
                          )}
                      </TranscribeText>
                    </div>
                  )
                );
              })}
            </div>
          </TranscribeContainer>
        </TranscribeWrapContainer>
        <KeywordsContainer>
          {foundKeywordsRef.current.map((keyword) => {
            return (
              <Keyword
                key={[keyword.id, keyword.term].join("")}
                id={[keyword.id, keyword.term].join("")}
                level={keyword.data ? keyword.data.level : 1}
                onClick={() => {
                  const chatMsg = document.getElementById(`transcribe-msg-${keyword.id}`);
                  if (chatMsg) {
                    chatMsg.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
                    chatMsg.classList.add("highlight");
                    setTimeout(() => {
                      chatMsg.classList.remove("highlight");
                    }, 1_000);
                  }
                }}
              >
                {keyword.term}
                <KeywordTimestamp>{getTimestamp(keyword.created_at)}</KeywordTimestamp>
              </Keyword>
            );
          })}
        </KeywordsContainer>
      </TranscribeWrap>
    </div>
  );
};

const mapStateToProps = ({ app, agency, conversations }: IRootState) => ({
  app,
  agency,
  conversations
});

export const TranscribeChat = connect(mapStateToProps, {})(XTranscribeChat);
