import { createRoot, RootType } from "react-dom/client";
import React from "react";
import { TranscribeChat, Provider } from "@components";
import { getStore } from "@utils";
import { appendAsChild } from "@lib/utils/helpers";
import { appendContainer } from "@actions";

interface IConveyTranscribeOpts {
  $container: string;
  correlationId: string;
  neuralName: string;
  root?: RootType;
  callerLanguage: string;
  talkback?: boolean;
  showUploader?: boolean;
  conversationId?: string;
  callback?: (eventData: Record<any, any>) => void;
  onKeywordCallback?: (eventData: Record<any, any>) => void;
}

export class ConveyTranscribe implements IConveyTranscribeOpts {
  $container: string;
  $element: HTMLElement;
  root?: RootType;
  correlationId: string;
  neuralName: string;
  callerLanguage: string;
  talkback?: boolean;
  showUploader?: boolean;
  conversationId?: string;
  callback?: (eventData: Record<any, any>) => void;
  onKeywordCallback?: (eventData: Record<any, any>) => void;

  constructor(params: IConveyTranscribeOpts) {
    const $element = document.getElementById(params.$container);
    if ($element) {
      const { fullId, $newElement } = appendAsChild(params.correlationId, $element, params.$container, "transcribe");

      getStore().dispatch(
        appendContainer({ phoneNumber: params.correlationId, id: fullId, status: "visible", experience: "transcribe" })
      );

      this.$element = $newElement;
      this.root = params.root;
      this.correlationId = params.correlationId;
      this.callerLanguage = params.callerLanguage;
      this.neuralName = params.neuralName;
      this.conversationId = params.conversationId;
      this.talkback = params.talkback === false ? false : true;
      this.showUploader = params.showUploader === false ? false : true;
      this.callback = params.callback;
      this.onKeywordCallback = params.onKeywordCallback;
      this.init();
    }
  }

  init() {
    if (!this.root) {
      this.root = createRoot(this.$element!);
    }

    this.root.render(
      <Provider>
        <TranscribeChat
          // @ts-ignore
          correlationId={this.correlationId}
          callerLanguage={this.callerLanguage}
          talkback={this.talkback}
          neuralName={this.neuralName}
          showUploader={this.showUploader}
          conversationId={this.conversationId}
          callback={this.callback}
          onKeywordCallback={this.onKeywordCallback}
        />
      </Provider>
    );
  }

  getRoot() {
    return this.root;
  }

  destroy() {
    if (this.root) {
      this.root.unmount();
      this.root = null;
      this.$element = null;
      this.correlationId = null;
      this.callerLanguage = null;
      this.neuralName = null;
      this.conversationId = null;
      this.talkback = false;
    }
  }
}
