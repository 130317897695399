import React, { FunctionComponent, useState, useEffect } from "react";
import { ConveyApi } from "@utils";
import { Modal } from "@components/Modal";

interface IAttachmentProps {
  conversationId: string;
  numMedia: number;
  messageId: string;
  imageUrl?: string;
}

export const Attachment: FunctionComponent<IAttachmentProps> = ({
  numMedia,
  messageId,
  imageUrl
}: IAttachmentProps) => {
  const [active, setActive] = useState(false);
  const [src, setSource] = useState(imageUrl || "");
  const [contentType, setContentType] = useState("");
  useEffect(() => {
    if (numMedia && numMedia > 0) {
      (async () => {
        const response = await ConveyApi.getAttachments({ messageId }).catch(() => null);
        if (response && response[0]) {
          setContentType(response[0].content_type);
          const imageResponse = await ConveyApi.getAttachmentSource({ attachmentId: response[0].attachment_id }).catch(
            () => null
          );
          setSource(imageResponse);
        }
      })();
    }
  }, [numMedia]);
  return (
    <>
      {active && (
        <Modal
          show={active}
          title="Image"
          onClose={() => {
            setActive(false);
          }}
          modalBody={<img src={src} style={{ maxWidth: 480 }} />}
        />
      )}
      <div id={`attachment-${messageId}`}>
        <>
          {contentType.includes("image/") && src && (
            <img
              onClick={() => {
                setActive(true);
              }}
              src={src}
              style={{ maxWidth: 180, cursor: "pointer" }}
            />
          )}
          {contentType.includes("audio/3gpp") ? (
            <video src={src} controls={true} width={240} />
          ) : (
            contentType.includes("audio") && <audio src={src} />
          )}
        </>
      </div>
    </>
  );
};
